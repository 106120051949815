const createEnvironmentFlags = (development = false, staging = false, production = false) => ({
  development,
  staging,
  production,
});

export const FLAGS = {
  confirmExitModalFlag: createEnvironmentFlags(true, true, false), // WEDDINGS-1785
  expeditedShippingModal: createEnvironmentFlags(false, false, false), // INV-1439

  // Toggle datadog monitoring in react-pages
  // For next pages, see next.config.js
  datadog: createEnvironmentFlags(false, true, true),

  envelopeGuestAddressingTextResets: createEnvironmentFlags(false, false, false), // COMMERCE-1153 follow up
  resetCustomizationButton: createEnvironmentFlags(false, false, false), // COMMERCE-1476
  holidayShipping: createEnvironmentFlags(true, true, true),
  paperGuestAddressingConfirmationModal: createEnvironmentFlags(false, false, false), // Don't delete. Waiting for guest addressing experiment to be over before reenabling.
  vendorSearchInWeddingLocationStep: createEnvironmentFlags(false, false, false), // https://newamsterdamlabs.atlassian.net/browse/CE-2390
  deleteEventWithSeatingChart: createEnvironmentFlags(true, true, true),
  seatingChartV2Released: createEnvironmentFlags(true, true, true),
  letterpressEnvelopePricing: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/PE-986
  pushSignupIntoCustomization: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/PE-980
  croppedText: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/PE-1044
  enableApiControlledPdpImages: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/PE-1028
  snappingCustomization: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/PE-1077
  budgetRevamp: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/PE-1285
  websiteAnimations: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/PE-1285
};

type Environments = 'development' | 'staging' | 'production';
export const getEnvironment = (): Environments => {
  const zolaEnv: string | undefined =
    (typeof window !== 'undefined' && window.zola && window.zola.env) ||
    process.env.ZOLA_ENV ||
    process.env.NODE_ENV;
  switch (zolaEnv) {
    case 'test':
    case 'development':
    case 'dev':
      return 'development';
    case 'staging':
    case 'stage':
      return 'staging';
    case 'production':
    case 'prod':
      return 'production';
    default:
      return 'production';
  }
};

export type FeatureFlag = keyof typeof FLAGS;

const get = (flag: FeatureFlag): boolean => {
  if (FLAGS[flag]) {
    return Boolean(FLAGS[flag][getEnvironment()]);
  }
  return false;
};

export default {
  get,
  getEnvironment,
};
