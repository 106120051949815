/**
 * Attempt to split up these routes and make this more manageable
 */
import React from 'react';
import { Route, IndexRoute, IndexRedirect, browserHistory } from 'react-router';
import _get from 'lodash/get';
import { trackOnboardingCompleted } from '@zola-helpers/client/dist/es/tracking/onboard/onboardEvents';
import onboardTrackingConstants from '@zola-helpers/client/dist/es/tracking/onboard/onboardTrackingConstants';

// Actions
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import { getGuestRequestCount, getWeddingBySlug } from 'actions/PublicWebsiteActions';
import { fetchGuestList } from 'actions/GuestActions';
import { fetchEventsRsvpPage, fetchEventsByDate, selectEvent } from 'actions/EventActions';
import { fetchChart, fetchChartList, finishOnboarding } from 'actions/SeatingChartActions';
import { submitOnboarding } from 'actions/OnboardActions';
import { getUserContext } from 'actions/UserActions';
import { getConnection } from 'actions/ZoomActions';
import { fetchGuestCollection } from 'actions/GuestCollectionActions';
import { fetchPages, getProfileImage } from 'actions/website/PageActions';
import { fetchRegistry } from 'actions/RegistryActions';
import { fetchWeddingAccount, fetchWedding } from 'actions/WeddingActions';
import { fetchBannerMessage } from 'actions/website/BannerActions';
import {
  manageWebsiteSetReady,
  manageWebsiteSetNotReady,
} from 'actions/website/ManageWebsiteActions';

// Util
import {
  getUserContext as selectUserContext,
  getUserHasCompletedOnboarding,
  getUserHasWeddingAccount,
  getUserIsGuest,
  getUserHasWebsite,
  getUserWeddingSlug,
} from 'selectors/user/userSelectors';
import MinimalLoader from 'components/common/ui/MinimalLoader';
import { asyncComponent } from 'util/asyncComponent';
import { getBudgetRoutes } from 'components/manage/budget/routes';
import { isMobile } from '@zola-helpers/client/dist/es/util/responsive';
import featureFlags from 'util/featureFlags';
import { redirectToNotFound } from '../routesHelpers';
import { getWeddingWebsiteThemeV2 } from '../actions/WeddingThemeActions';

// Components
const Directory = asyncComponent(() => import('components/manage/guestlist/Directory'));
const Build = asyncComponent(() => import('components/manage/guestlist/Build'));
const AddressEnvelopes = asyncComponent(() =>
  import('components/manage/guestlist/AddressEnvelopes')
);
const RsvpOverview = asyncComponent(() => import('components/manage/guestlist/RsvpOverview'));
const TrackRsvpNotifications = asyncComponent(() =>
  import('components/manage/guestlist/trackRsvpNotifications/TrackRsvpNotifications')
);
const MultipleRsvpNotifications = asyncComponent(() =>
  import('components/manage/guestlist/trackRsvpNotifications/MultipleRsvpNotifications')
);
const AddGuestPage = asyncComponent(() =>
  import('components/manage/guestlist/addGuestPage/AddGuestPage')
);
const RsvpEvent = asyncComponent(() => import('components/manage/guestlist/RsvpEvent'));
const SeatingChart = asyncComponent(() => import('components/manage/seatingchart/SeatingChart'));
const ChecklistContainer = asyncComponent(() => import('components/manage/ChecklistV2'));

const ZoomConnect = asyncComponent(() => import('components/manage/VirtualEvents/ZoomConnect'));
const ZoomCreate = asyncComponent(() => import('components/manage/VirtualEvents/ZoomCreate'));
const ZoomEdit = asyncComponent(() => import('components/manage/VirtualEvents/ZoomEdit'));

const ChecklistV2 = asyncComponent(() =>
  import(/* webpackChunkName: "ChecklistV2" */ 'components/manage/ChecklistV2')
);

const Manage = asyncComponent(() => import(/* webpackChunkName: "Manage" */ '../pages/Manage'));

const Events = asyncComponent(() =>
  import(/* webpackChunkName: "Events" */ '../pages/EventsContainer')
);

const GuestsV3 = asyncComponent(() =>
  import(/* webpackChunkName: "GuestsV3" */ '../pages/GuestsV3')
);

const TrackRsvpsContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: "TrackRsvpsContainer" */ 'components/manage/guestlist/TrackRsvpsContainer'
  )
);

const SeatingList = asyncComponent(() =>
  import(/* webpackChunkName: "SeatingList" */ 'components/manage/seatingchart/SeatingList')
);

const Onboarding = asyncComponent(() =>
  import(
    /* webpackChunkName: "Onboarding" */ 'components/manage/seatingchart/Onboarding/Onboarding'
  )
);

const VirtualEventOverview = asyncComponent(() =>
  import(
    /* webpackChunkName: "VirtualEventOverview" */ 'components/manage/website/VirtualEventOverview'
  )
);

const ManageWebsite = asyncComponent(() =>
  import(/* webpackChunkName: "ManageWebsite" */ 'components/manage/ManageWebsite/')
);

const VirtualEvents = asyncComponent(() =>
  import(/* webpackChunkName: "VirtualEvents" */ 'components/manage/VirtualEvents')
);

const ContactCollectorCoupleView = asyncComponent(() =>
  import(
    /* webpackChunkName: "ContactCollectorCoupleView" */ '../components/manage/guestlist/ContactCollectorCoupleView'
  )
);

const EditWebsite = asyncComponent(() =>
  import(/* webpackChunkName: "EditWebsite" */ '../components/manage/EditWebsite')
);

const EditWebsiteEditPages = asyncComponent(() =>
  import(
    /* webpackChunkName: EditWebsiteEditPages */ 'components/manage/EditWebsite/EditWebsiteEditPages'
  )
);

const EditWebsitePagesMapper = asyncComponent(() =>
  import(
    /* webpackChunkName: "EditWebsitePagesMapper" */ 'components/manage/EditWebsite/EditWebsitePagesMapper'
  )
);

const EditWebsiteCustomizeSite = asyncComponent(() =>
  import(
    /* webpackChunkName: "EditWebsiteCustomizeSite" */ 'components/manage/EditWebsite/EditWebsiteCustomizeSite'
  )
);

const EditWebsiteAddEffects = asyncComponent(() =>
  import(
    /* webpackChunkName: "EditWebsiteAddEffects" */ 'components/manage/EditWebsite/EditWebsiteAddEffects/EditWebsiteAddEffects'
  )
);

const EditWebsiteChangeDesignV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "EditWebsiteChangeDesignV2" */ 'components/manage/EditWebsite/EditWebsiteChangeDesignV2'
  )
);

const { BUSINESS_UNIT, COMPONENT } = onboardTrackingConstants;

const getWeddingManageRoutes = store => {
  const redirect = ({ to, useSlug }) => nextState => {
    const slug = useSlug ? `/${nextState.params[useSlug]}` : '';
    window.location = `${to}${slug}`;
  };

  const checkWebsite = (nextState, replaceState, callback) => {
    const { dispatch, getState } = store;
    dispatch(getUserContext()).then(() => {
      const state = getState();
      const hasWebsite = getUserHasWebsite(state);
      if (!hasWebsite) {
        window.location.href = '/wedding-planning/website';
      } else {
        callback();
      }
    });
  };

  const doVirtualEventRedirects = (nextState, replaceState, callback) => {
    const { getState } = store;
    const state = getState();
    const { pathname } = nextState.location;
    const isGuest = getUserIsGuest(state);
    const hasWebsite = getUserHasWebsite(state);
    if (isGuest) {
      window.location.href = `/account/login?returnTo=${pathname}`;
      callback();
    }
    if (!isGuest && !hasWebsite) {
      window.location.href = '/wedding-planning/website';
    }
  };

  const checkRegistryAndRedirect = () => {
    const { dispatch } = store;

    dispatch(getUserContext())
      .then(() => {
        const state = store.getState();
        if (state.user.userContext.registry_ids.length > 0) {
          window.location = '/registry/dashboard';
        } else {
          window.location = '/onboard/new';
        }
      })
      .catch(() => {
        window.location = '/onboard/new';
      });
  };

  const checkWeddingAccountForPlanningTools = (nextState, replaceState, callback) => {
    const { dispatch, getState } = store;
    const state = getState();
    const { pathname } = nextState.location;
    const planningDashboard = pathname === '/wedding/manage';
    const weddingManageRegistry =
      pathname === '/wedding/manage/registry-onboard' || pathname === '/wedding/manage/registry';
    const isBudgetPage = pathname.includes('/wedding/manage/budget');

    const isVirtualEventConnectPage = pathname === '/wedding/manage/virtual/connect';

    if (!getUserHasCompletedOnboarding(state)) {
      if (planningDashboard) {
        // Send to wedding planning onboarding flow
        replaceState('/wedding/onboard/wedding-planning');
        callback();
      } else if (weddingManageRegistry) {
        // Send to registry onboarding flow
        window.location.href = '/onboard/new';
      } else if (isVirtualEventConnectPage) {
        // Redirects guests to login page, users without a website to the website LP
        doVirtualEventRedirects(nextState, replaceState, callback);
        return;
      } else {
        // Send to planning tools landing page (all others requires a weddings account)
        window.location.href = '/wedding-planning';
      }
      return;
    }

    // Auto-onboard existing users to Planning Dashboard
    if (planningDashboard && !getUserHasWeddingAccount(state)) {
      // create a wedding account
      const onboardingData = {
        existing_account: true,
        business_unit: COMPONENT.WEDDING_PLANNING,
        answers: [],
      };
      dispatch(submitOnboarding(onboardingData)).then(() => {
        trackOnboardingCompleted(BUSINESS_UNIT.WEDDINGS, COMPONENT.WEDDING_PLANNING, {
          isExistingUser: true,
        });
        trackOnboardingCompleted(BUSINESS_UNIT.WEDDINGS, COMPONENT.WEDDINGS, {
          isExistingUser: true,
        });
        replaceState('/wedding/manage');
        callback();
      });
    } else if (planningDashboard) {
      window.location.href = '/wedding/manage';
    } else if (isBudgetPage) {
      callback();
    } else if (!getUserHasWeddingAccount(state)) {
      // Send to planning tools landing page (all others requires a weddings account)
      if (isVirtualEventConnectPage) {
        window.location.href = '/wedding-planning/website';
      } else {
        window.location.href = '/wedding-planning';
      }
    } else {
      dispatch(fetchWeddingAccount())
        .then(() => {
          callback();
        })
        .catch(() => {
          // Send to pre-auth planning tools landing page if failed to fetch wedding account
          window.location.href = '/wedding-planning';
        });
    }
  };

  const isOnboarding = (nextState, replaceState, callback) => {
    const { dispatch } = store;
    dispatch(fetchWeddingAccount())
      .then(() => {
        const state = store.getState();
        if (!state.wedding.account.disable_website_onboarding) {
          // returning to prevent a flash of the ManageWebsite page from displaying
          return window.location.assign('/wedding/manage/website/designs');
        }
        return callback();
      })
      .catch(() => {
        window.location.href = '/wedding-planning';
      });
  };

  const isMwebGatedCheck = (nextState, replaceState, callback) => {
    if (isMobile()) {
      replaceState('/wedding/manage/website/manage');
      callback();
    } else {
      isOnboarding(nextState, replaceState, callback);
    }
  };

  const checkEvent = (nextState, replaceState, callback) => {
    const { dispatch } = store;
    const { eventUUID } = nextState.params;
    dispatch(fetchEventsRsvpPage())
      .then(() => {
        const { events } = store.getState().events;
        const eventId = events.uuidToId[eventUUID];
        if (!eventId) {
          replaceState('/wedding/manage/guests/rsvps/overview');
        }
        callback();
      })
      .catch(() => {
        replaceState('/wedding/manage/guests/rsvps/overview');
        callback();
      });
  };

  const checkGuestRequests = (nextState, replaceState, callback) => {
    const { dispatch } = store;
    dispatch(getGuestRequestCount())
      .then(() => getGuestRequestCount())
      .then(() => {
        const notificationCount = store.getState().publicWebsite.guestRequestCount;
        if (notificationCount === 0) {
          replaceState('/wedding/manage/guests/all');
        }
        callback();
      })
      .catch(() => {
        replaceState('/wedding/manage/guests/all');
        callback();
      });
  };

  const checkGuestList = (nextState, replaceState, callback) => {
    const { dispatch } = store;
    dispatch(fetchGuestList()).then(() => {
      callback();
    });
  };

  const checkSeatingChartVersion = (nextState, replaceState, callback) => {
    const { dispatch } = store;

    dispatch(getUserContext())
      .then(() => {
        const state = store.getState();
        const { wedding_account } = state.user.userContext;
        const isSeatingChartV1 = wedding_account && Boolean(wedding_account.seating_chart_v1);

        if (!isSeatingChartV1) {
          window.location = featureFlags.get('seatingChartV2Released')
            ? '/wedding-planning/seating-chart'
            : '/wedding/manage';
          return;
        }

        callback();
      })
      .catch(() => {
        window.location = '/wedding/manage';
      });
  };

  // onEnter hook for Seating Chart landing
  const fetchAllSeatingCharts = (nextState, replaceState, callback) => {
    const { dispatch } = store;
    dispatch(fetchEventsByDate());
    dispatch(fetchChartList()).then(() => callback());
  };

  const checkZoomAuth = () => {
    const { dispatch } = store;
    dispatch(getConnection()).then(response => {
      if (response) browserHistory.push('/wedding/manage/virtual/create/new');
    });
  };

  const checkWebsiteV2 = (nextState, replaceState, callback) => {
    isOnboarding(nextState, replaceState, callback);
    const { getState, dispatch } = store;
    const state = getState();
    const slug = getUserWeddingSlug(state);
    dispatch(getWeddingBySlug(slug));
  };

  const checkGuestCollection = (nextState, replaceState) => {
    const { dispatch, getState } = store;
    const isGuest = getUserIsGuest(getState());
    if (isGuest) return replaceState('/wedding/onboard/wedding-guest-list');
    const state = getState();
    const slug = getUserWeddingSlug(state);
    dispatch(getWeddingBySlug(slug));
    return dispatch(fetchGuestCollection(true));
  };

  const fetchWebsiteCustomization = (nextState, replaceState, callback) => {
    isMwebGatedCheck(nextState, replaceState, callback);
    const { dispatch } = store;

    dispatch(getWeddingWebsiteThemeV2());
    dispatch(fetchPages());
    dispatch(fetchWeddingAccount());
  };

  const checkWebsiteChangeDesign = (nextState, replaceState, callback) => {
    const { dispatch } = store;

    dispatch(getWeddingWebsiteThemeV2());
    dispatch(fetchPages());
    dispatch(fetchWeddingAccount());
    isMwebGatedCheck(nextState, replaceState, callback);
  };

  const checkAddEffectsExperiment = (nextState, replaceState, callback) => {
    if (typeof window !== 'undefined' && !featureFlags.get('websiteAnimations')) {
      redirectToNotFound();
    } else {
      fetchWebsiteCustomization(nextState, replaceState, callback);
    }
  };

  const manageWebsiteSetup = (nextState, replaceState, callback) => {
    isOnboarding(nextState, replaceState, callback);
    const { getState, dispatch } = store;
    const state = getState();
    const userContext = selectUserContext(state);
    const errorMsg =
      'Error fetching website data. Please refresh the page or contact customer support if error persists.';

    Promise.all([
      dispatch(fetchPages()),
      dispatch(fetchGuestList()),
      dispatch(fetchBannerMessage()),
      dispatch(fetchGuestCollection()),
      dispatch(getWeddingWebsiteThemeV2()),
      dispatch(fetchWeddingAccount()),
      dispatch(manageWebsiteSetReady()),
    ]).catch(() => {
      dispatch(manageWebsiteSetNotReady());
      dispatch(toastsActions.negative({ headline: errorMsg }));
    });
    if (userContext.has_registry && !state.registry.status) {
      Promise.all([
        dispatch(fetchRegistry()),
        dispatch(getProfileImage()),
        dispatch(manageWebsiteSetReady()),
      ]).catch(() => {
        dispatch(manageWebsiteSetNotReady());
        dispatch(toastsActions.negative({ headline: errorMsg }));
      });
    }
  };

  // onEnter hook for Seating Chart landing
  // Fetch seating chart by uuid
  const fetchSeatingChart = (nextState, replaceState, callback) => {
    const { dispatch } = store;
    const { uuid } = nextState.params;
    if (uuid != null) {
      dispatch(fetchChart(uuid))
        .catch(() => {
          redirectToNotFound();
        })
        .then(() => {
          dispatch(fetchEventsByDate());
          dispatch(finishOnboarding());
          dispatch(fetchChartList()).then(() => callback());
        });
    } else {
      // To handle browser back/forward buttons during/directly after onboarding (will need to change with more entry points)
      const state = store.getState();
      if (state.seatingChart != null && state.seatingChart.onboarding) {
        callback();
      } else {
        replaceState('/wedding/manage/seating');
        callback();
      }
    }
  };

  const renderUserChecklist = () => {
    return (
      <React.Fragment>
        <Route path="wedding-checklist">
          <IndexRoute component={ChecklistV2} />
          <Route path=":id" component={ChecklistContainer} />
        </Route>
        <Route path="checklist" onEnter={redirect({ to: '/wedding/manage/wedding-checklist' })} />
      </React.Fragment>
    );
  };

  const onEnterEditWebsitePages = async (nextState, replaceState, callback) => {
    const { dispatch } = store;
    await dispatch(fetchWedding);
    isMwebGatedCheck(nextState, replaceState, callback);
    callback();
  };

  return (
    <Route path="manage" component={Manage} onEnter={checkWeddingAccountForPlanningTools}>
      <Route path="events" component={Events} />
      {renderUserChecklist()}
      {getBudgetRoutes()}
      <Route path="registry" onEnter={checkRegistryAndRedirect} />
      <Route path="registry-onboard" onEnter={checkRegistryAndRedirect} />
      <Route
        path="guests"
        component={GuestsV3}
        onEnter={checkGuestList} // TODO: specific children are calling different lists, move onEnters to children
      >
        <IndexRedirect to="all" />
        <Route path="all" component={Directory} />
        <Route path="build" component={Build} />
        <Route path="envelopes" component={AddressEnvelopes} />
        <Route path="collection-link" onEnter={checkGuestCollection}>
          <IndexRoute component={ContactCollectorCoupleView} />
          <Route path="configure" component={ContactCollectorCoupleView} />
        </Route>
        <Route>
          <Route
            path="rsvp-notification/:rsvpUUID"
            component={TrackRsvpNotifications}
            onEnter={checkGuestRequests}
          />
          <Route path="rsvp-notifications" component={MultipleRsvpNotifications} />
          <Route path="add-guest/:rsvpUUID" component={AddGuestPage} />
        </Route>
        <Route path="rsvps" component={TrackRsvpsContainer}>
          <IndexRedirect to="overview" />
          <Route path="overview" component={RsvpOverview} />
          <Route path=":eventUUID" component={RsvpEvent} onEnter={checkEvent} />
        </Route>
        <Route path="thank-you-notes" />
      </Route>
      <Route onEnter={checkSeatingChartVersion}>
        <Route path="seating" component={SeatingList} onEnter={fetchAllSeatingCharts} />
        <Route path="seating-chart" component={Onboarding} onEnter={fetchSeatingChart} />
        <Route path="seating-chart/:uuid" component={SeatingChart} onEnter={fetchSeatingChart} />
      </Route>
      <Route
        path="website"
        onEnter={checkWebsiteV2}
        getComponent={(location, callback) => {
          callback(null, EditWebsite);
        }}
      >
        <IndexRedirect to="manage" />
        <Route
          path="virtual-event/:eventHash"
          component={VirtualEventOverview}
          onEnter={checkWebsite}
        />

        <Route
          path="pages"
          onEnter={onEnterEditWebsitePages}
          getComponent={(location, callback) => {
            callback(null, EditWebsiteEditPages);
          }}
        >
          <Route
            path=":pageKey"
            getComponent={(location, callback) => {
              callback(null, EditWebsitePagesMapper);
            }}
          />
        </Route>
        <Route path="manage" onEnter={manageWebsiteSetup} component={ManageWebsite} />
        <Route path="themes" onEnter={redirect({ to: '/wedding/manage/website/designs' })} />
        <Route
          path="theme/:themeKey"
          onEnter={redirect({ to: '/wedding/manage/website/design', useSlug: 'themeKey' })}
        />
        <Route
          path="design/:themeKey"
          component={location => {
            const { themeKey } = location.params;
            // Keep for internal redirects for now.
            window.location.assign(
              `${window.location.protocol}//${window.location.host}/wedding/manage/website/design/${themeKey}`
            );
          }}
        />
        <Route
          path="designs"
          onEnter={() => {
            // Keep for internal redirects for now.
            window.location.assign(
              `${window.location.protocol}//${window.location.host}/wedding/manage/website/designs`
            );
          }}
          component={MinimalLoader}
        />
        <Route
          path="customize"
          getComponent={(location, callback) => {
            callback(null, EditWebsiteCustomizeSite);
          }}
          onEnter={fetchWebsiteCustomization}
        />
        <Route
          path="add-effects"
          getComponent={(location, callback) => {
            callback(null, EditWebsiteAddEffects);
          }}
          onEnter={checkAddEffectsExperiment}
        />
        <Route
          path="change-design"
          getComponent={(location, callback) => {
            callback(null, EditWebsiteChangeDesignV2);
          }}
          onEnter={checkWebsiteChangeDesign}
        />
      </Route>
      <Route path="virtual" component={VirtualEvents} onEnter={checkWebsite}>
        <IndexRedirect to="connect" />
        <Route path="connect" component={ZoomConnect} onEnter={checkZoomAuth} />
        <Route path="create" component={ZoomCreate}>
          <IndexRedirect to="new" />
          <Route path="new" />
          <Route path=":eventHash" />
        </Route>
        <Route
          path="edit/:eventHash"
          component={ZoomEdit}
          onEnter={nextState => selectEvent(_get(nextState, 'params.eventHash'))}
        />
      </Route>
    </Route>
  );
};

export default getWeddingManageRoutes;
