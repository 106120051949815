import ApiService from '@zola-helpers/client/dist/es/http/api';
import type { WAccountPremiumFeatureView } from '@zola/svc-web-api-ts-client';

export const enablePremiumFeature = (premium_feature: string) =>
  ApiService.post<WAccountPremiumFeatureView>(`/web-api/v1/premium-feature`, {
    premium_feature,
  }).then(r => !!r.enabled_at);

export const getIsPremiumFeatureEnabled = (key: string) =>
  ApiService.post<boolean>(`/web-api/v1/premium-feature/feature/${key}/is-enabled`);
